<template>
  <div>
    <Row :gutter="5" class="m-b-5">
      <i-col :xs="24" :sm="12" :md="6" class="m-b-5">
        <DatePicker  size="small" placeholder="合同的开始时间" v-model="invoiceQuery.startDate" @on-change="invoiceQuery.startDate=$event" style="width:100%"></DatePicker>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6" class="m-b-5">
        <DatePicker  size="small" placeholder="合同的结束时间" v-model="invoiceQuery.endDate" @on-change="invoiceQuery.endDate=$event" style="width:100%"></DatePicker>
      </i-col>
      <i-col  v-if="isAuth('Invoice_Manage_View')" :xs="24" :sm="12" :md="6" class="m-b-5">
          <Select size="small" v-model="invoiceQuery.companyId" placeholder="所属公司" clearable @on-change="companyChange">
          <Option v-for="(channel,index) in channellist" :key="index" :value="channel.id">{{channel.name}}</Option>
          </Select>
      </i-col>

            <i-col  v-if="isAuth('Invoice_Manage_View')" :xs="24" :sm="12" :md="6" class="m-b-5">
                <Select size="small" v-model="invoiceQuery.departmentId" placeholder="所属部门" clearable @on-change="departmentChange">
                  <Option v-for="(department,index) in departments" :key="index" :value="department.id">{{department.name}}</Option>
                </Select>
            </i-col>
      <i-col v-if="isAuth('Invoice_Manage_View')"  :xs="24" :sm="12" :md="6" class="m-b-5">
          <Select size="small" v-model="invoiceQuery.userId" placeholder="归属销售" clearable>
          <Option v-for="user in channelUsers" :key="user.id" :value="user.id">{{ user.name}}</Option>
          </Select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6" class="m-b-5">
        <i-select  size="small" placeholder="状态" v-model="invoiceQuery.invoiceProgress"  >
            <i-option :value="0">未开票</i-option>
            <i-option :value="1">部分开票</i-option>
            <i-option :value="2">完全开票</i-option>
        </i-select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6" class="m-b-5">
        <i-input  size="small" placeholder="关键字：支持名称查询" v-model="invoiceQuery.keyword"></i-input>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6" class="m-b-5">
        <i-button size="small"  type="primary"  icon='ios-search'  @click="handleSearch">搜索</i-button>
      </i-col>
    </Row>

    <i-table :data="list" :columns="titles" stripe></i-table>
    <div class="paging_style">
        <Page size="small" show-total show-elevator  :page-size="invoiceQuery.pageSize" :total="total" @on-change="handlePageChanged"></Page>
    </div>
  </div>
</template>

<script>
import { getBusinessPage } from '@/api/scp/invoice'

import { toMoney } from '@/utils/wnumb_own'
import { GetCurrentSchedule } from '@/utils/dateFormat.js'
import { formatInvoiceStatus } from '@/utils/tagStatus'
import { getCompanyChild } from '@/api/os/company'
import { getSellerPage } from '@/api/os/companyuser'

export default {
  data () {
    return {
      channellist: [],
      departments: [],
      channelUsers: [],
      list: [],
      total: 0,
      titles: [
        { title: '编码', key: 'code' },
        { title: '名称', key: 'name' },
        { title: '客户', key: 'advertiserName' },
        {
          title: '档期',
          render: (h, data) => {
            if (data.row.startDate) {
              return h('div', [
                h('span', GetCurrentSchedule(data.row.startDate, data.row.endDate))
              ])
            } else {
              return h('span', '--')
            }
          }
        },
        {
          title: '合同金额',
          align: 'right',
          render: (h, data) => {
            return h('span', {
              attrs: {
                style: 'color:#44bd32'
              }
            }, toMoney(data.row.amount))
          }
        },
        {
          title: '已开票金额(含税)',
          align: 'right',
          render: (h, data) => {
            return h('span', {
              attrs: {
                style: 'color:#44bd32'
              }
            }, toMoney(data.row.invoicedAmount))
          }
        },
        {
          title: '状态',
          align: 'center',
          render: (h, data) => {
            let status = 0
            let statusName = ''
            if (data.row.invoicedAmount === 0 && data.row.amount > 0) {
              status = 0
              statusName = '未开票'
            } else if (data.row.invoicedAmount < data.row.amount) {
              status = 1
              statusName = '部分开票'
            } else {
              status = 2
              statusName = '完全开票'
            }

            return formatInvoiceStatus(h, status, statusName)
          }
        },
        { title: '备注', key: 'mark' },
        {
          title: '操作',
          render: (h, data) => {
            return h('div', [
              h('a', {
                on: {
                  click: () => {
                    this.handleShowDetail(data.row.id)
                  }
                }
              }, '查看详情')
            ])
          }
        }
      ]

    }
  },
  methods: {
    initPage () {
      this.channellist = []
      getCompanyChild({ companyId: this.$store.getters.token.userInfo.companyId, type: this.$store.getters.token.userInfo.publisherId === this.$store.getters.token.userInfo.companyId ? 1 : 2, childType: 2, companyType: 1 }).then(res => {
        let array = [{
          id: res.companyId, name: res.companyName
        }]
        if (res.children.length) {
          array = array.concat(this.formatCompanyArray(res.children).filter(x => x.id !== res.companyId))
        }
        this.channellist = array
        if (!this.invoiceQuery.companyId) {
          if (array.length > 1 && this.isAuth('Invoice_Manage_View')) {
            this.invoiceQuery.companyId = null
          } else {
            this.invoiceQuery.companyId = this.$store.getters.token.userInfo.companyId
          }
        }
        this.initdepartments()
      })
    },
    companyChange () {
      this.invoiceQuery.departmentId = ''
      this.invoiceQuery.userId = ''
      this.initdepartments()
    },
    departmentChange () {
      this.invoiceQuery.userId = ''
      this.getChannelUserList()
    },
    initdepartments () {
      this.departments = []
      if (this.invoiceQuery.companyId) {
        getCompanyChild({ companyId: this.invoiceQuery.companyId, type: 2, childType: 2, companyType: 2 }).then(res => {
          let array = [{
            id: res.companyId, name: res.companyName
          }]
          if (res.children.length) {
            array = array.concat(this.formatCompanyArray(res.children).filter(x => x.id !== res.companyId))
          }
          this.departments = array
          // this.invoiceQuery.departmentId = null

          this.getChannelUserList()
        })
      } else {
        this.getChannelUserList()
      }
    },

    // 重组公司列表结构
    formatCompanyArray (companyTree) {
      let resultArray = []
      companyTree.forEach(item => {
        resultArray.push({ id: item.companyId, name: item.companyName })
        if (item.children.length) {
          resultArray = resultArray.concat(this.formatCompanyArray(item.children))
        }
      })
      return resultArray
    },
    getChannelUserList () {
      this.channelUsers = []
      const data = {
        companyId: this.invoiceQuery.departmentId || this.invoiceQuery.companyId,
        pageNumber: 1,
        pageSize: 1000
      }
      if (data.companyId) {
        getSellerPage(data).then(response => {
          this.channelUsers = response.list
        })
      } else {
        this.invoiceQuery.userId = ''
      }
      this.getData()
    },
    getData () {
      // this.invoiceQuery.userId = this.$store.getters.token.userInfo.userId
      this.invoiceQuery.invoiceProgressList = JSON.stringify([this.invoiceQuery.invoiceProgress])
      this.$store.commit('set_query_invoiceQuery', this.invoiceQuery)
      getBusinessPage(this.invoiceQuery).then(res => {
        this.total = res.totalRow
        this.list = res.list
      })
    },
    handleShowDetail (invoiceId) {
      this.$emit('on-detail', invoiceId)
    },
    handleSearch () {
      this.invoiceQuery.pageNumber = 1
      this.getData()
    },
    handlePageChanged (page) {
      this.invoiceQuery.pageNumber = page
      this.getData()
    }
  },
  created () {
    this.invoiceQuery.userId = this.isAuth('Invoice_Manage_View') ? this.invoiceQuery.userId : this.$store.getters.token.userInfo.userId
    this.initPage()
  },
  computed: {
    invoiceQuery () {
      return this.$store.state.contractQuery.invoiceQuery
    }
  }

}
</script>
